<template>
	<div id="app">
		<b-overlay :show="isLoading" rounded="sm" class="h-100">
			<router-view />
			<SvgSprite/>
		</b-overlay>
	</div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "@/assets/scss/common.scss";
import SvgSprite from "./components/SvgSprite";

export default {
	name: "App",
	components: {
		SvgSprite
	},
	computed: {
		isLoading: function () {
			return this.$store.getters.getIsLoading;
		}
	}
};
</script>

<style></style>
