const DB_VERSION = '1'
const STORE_URL = window.location.href.split("/");
let DB;
const stores = [
    { name : "orderItems", key:"local_id"}
]

export default {
    async initialize() {
        return new Promise((resolve, reject) => { 
            if(DB) return resolve(DB)
            const request = window.indexedDB.open(STORE_URL[STORE_URL.length - 1], DB_VERSION)
            request.onerror = e => reject(e)
            request.onsuccess = e => { DB = e.target.result; resolve(DB) }
            request.onupgradeneeded = e => { 
                const db = e.target.result
                stores.forEach(store => { 
                    try {
                    if(db.objectStoreNames.contains(store.name))
                        db.deleteObjectStore(store.name)
                        db.createObjectStore(store.name, { keyPath: store.key })
                    }
                    catch (err) {
                        console.log(err)
                    }
                })
            }
        })
    },

    async saveToDb(key, data) { 
        const db  = await this.initialize()
        return new Promise((resolve) => {
            try {
                const trans = db.transaction([key], 'readwrite')
                trans.oncomplete = () => resolve()
                const store = trans.objectStore(key);
                if (Array.isArray(data)) {
                    data.forEach(updateOrder => store.put(updateOrder))
                }
                else {
                    store.put(data);
                }
            }
            catch (err) { 
                //pass
            }
        })
    },

    async getDbData(key) {
        const db = await this.initialize();
        return new Promise(resolve => {
            try {
                const trans = db.transaction([key], 'readonly');
                trans.oncomplete = () => {
                    resolve(dbData);
                };
                const store = trans.objectStore(key);
                const dbData = [];
                store.openCursor().onsuccess = e => {
                    const cursor = e.target.result;
                    if (cursor) {
                        dbData.push(cursor.value)
                        cursor.continue();
                    }
                };
            }
            catch (err) { 
                console.log(err,key)
            }
		});
    },

    async clearAllData(store = null) { 
        /**
         * @param {Array ,String} [store] - array of index db store names of name of index db store
         * 
         * @example
         *  clearAllData()
         *  clearAllData(["orders","categories"])
         *  clearAllData("orders")
         */
        const db = await this.initialize()
        return new Promise((resolve, reject) => { 
            try {
                if (!store) {
                    stores.forEach(store => { 
                    const trans = db.transaction(store.name, 'readwrite')
                    const requestedStore = trans.objectStore(store.name)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                    })
                }
                else if (Array.isArray(store)) {
                    store.forEach(store => { 
                    const trans = db.transaction(store, 'readwrite')
                    const requestedStore = trans.objectStore(store)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                    })
                }
                else if (typeof store === "string") {
                    const trans = db.transaction([store], 'readwrite')
                    const requestedStore = trans.objectStore(store)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                }
            }
            catch (err) {
                reject()
            }
            
        })
    },

    async deleteSingleInstance(storeName, instanceKey){
        const db = await this.initialize()
        return new Promise((resolve, reject)=>{
            try{
                const trans = db.transaction(storeName, 'readwrite')
                const requestedStore = trans.objectStore(storeName)
                requestedStore.delete(instanceKey)
                trans.oncomplete = () => { resolve() }
            }
            catch(err){
                reject(err)
            }

        })
        
    }

}