<template>
	<div style="height: 0; width: 0; position: absolute; visibility: hidden">
		<svg
			aria-hidden="true"
			style="position: absolute; width: 0; height: 0; overflow: hidden;"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<symbol id="icon-basket" viewBox="0 0 32 32">
					<path
						d="M1.333 0c-0.737 0-1.333 0.596-1.333 1.333s0.596 1.333 1.333 1.333c0.737 0 1.333-0.596 1.333-1.333h3.104c1.391 0 1.924 0.445 2.375 1.958l5.333 21.521c0.284 1.073 0.604 2.26 1.813 2.833-0.38 0.461-0.625 1.044-0.625 1.688 0 1.471 1.195 2.667 2.667 2.667s2.667-1.195 2.667-2.667c0-0.487-0.146-0.94-0.375-1.333h4.083c-0.229 0.393-0.375 0.846-0.375 1.333 0 1.471 1.195 2.667 2.667 2.667s2.667-1.195 2.667-2.667c0-0.693-0.273-1.318-0.708-1.792 0.023-0.068 0.042-0.133 0.042-0.208 0-0.367-0.3-0.667-0.667-0.667h-10.188c-1.797 0-1.992-0.719-2.375-2.167l-0.458-1.833h12.229c0.276 0 0.529-0.18 0.625-0.438l4.792-12.667c0.078-0.203 0.039-0.424-0.083-0.604s-0.326-0.292-0.542-0.292h-20.479l-1.417-5.729c-0.453-1.526-1.224-2.938-3.667-2.938h-4.438zM17.333 28c0.734 0 1.333 0.599 1.333 1.333s-0.599 1.333-1.333 1.333c-0.734 0-1.333-0.599-1.333-1.333s0.599-1.333 1.333-1.333zM26 28c0.734 0 1.333 0.599 1.333 1.333s-0.599 1.333-1.333 1.333c-0.734 0-1.333-0.599-1.333-1.333s0.599-1.333 1.333-1.333z"
					></path>
				</symbol>
				<symbol id="icon-ch" viewBox="0 0 46 32">
					<path
						fill="none"
						stroke-linejoin="round"
						stroke-linecap="round"
						stroke-miterlimit="4"
						stroke-width="4.5714"
						d="M41.143 4.571l-25.143 25.143-11.429-11.429"
					></path>
				</symbol>
				<symbol id="icon-dd" viewBox="0 0 40 32">
					<path
						d="M38.716 1.748c-0.594-1.077-1.753-1.748-3.028-1.748h-32.263c-1.275 0-2.434 0.67-3.028 1.748-0.565 1.028-0.526 2.235 0.103 3.227l16.132 25.449c0.624 0.988 1.717 1.577 2.924 1.577s2.3-0.588 2.926-1.575l16.132-25.449c0.628-0.994 0.667-2.201 0.101-3.228z"
					></path>
				</symbol>
				<symbol id="icon-edit" viewBox="0 0 32 32">
					<path
						d="M25.937 4.042l2.021 2.021-22.569 22.569h-2.021v-2.021l22.568-22.568zM25.937 0c-0.505 0-0.842 0.168-1.179 0.505l-24.758 24.758v6.737h6.737l24.758-24.758c0.673-0.674 0.673-1.684 0-2.358l-4.379-4.379c-0.337-0.337-0.674-0.505-1.179-0.505z"
					></path>
					<path d="M19.109 6.214l2.382-2.382 5.836 5.835-2.382 2.382-5.836-5.835z"></path>
				</symbol>
				<symbol id="icon-call" viewBox="0 0 32 32">
					<path
						stroke-linejoin="miter"
						stroke-linecap="butt"
						stroke-miterlimit="4"
						stroke-width="0.5818"
						d="M7.297 1.461c-0.509-0.041-1.074 0.106-1.595 0.472-0.459 0.32-1.451 1.107-2.377 1.998-0.462 0.444-0.903 0.906-1.244 1.36-0.343 0.454-0.643 0.87-0.625 1.474 0.018 0.542 0.072 2.145 1.226 4.602 1.154 2.455 3.392 5.823 7.757 10.191 4.368 4.367 7.736 6.605 10.191 7.759s4.060 1.208 4.602 1.226c0.601 0.018 1.017-0.281 1.471-0.625s0.916-0.782 1.36-1.244c0.888-0.927 1.675-1.918 1.995-2.377 0.73-1.040 0.609-2.269-0.204-2.839-0.511-0.359-5.212-3.487-5.919-3.921-0.718-0.439-1.544-0.24-2.282 0.15-0.581 0.307-2.153 1.208-2.602 1.466-0.338-0.214-1.621-1.069-4.38-3.828-2.762-2.759-3.614-4.042-3.828-4.38 0.258-0.449 1.156-2.013 1.466-2.605 0.387-0.736 0.604-1.569 0.142-2.292-0.194-0.307-1.074-1.647-1.962-2.992-0.891-1.345-1.735-2.62-1.954-2.925v-0.003c-0.287-0.395-0.733-0.627-1.239-0.669zM7.175 2.811c0.181 0.023 0.289 0.096 0.289 0.096 0.106 0.147 1.032 1.536 1.92 2.878 0.888 1.345 1.794 2.723 1.951 2.974 0.026 0.039 0.062 0.478-0.196 0.965v0.003c-0.292 0.555-1.652 2.932-1.652 2.932l-0.188 0.333 0.194 0.33c0 0 1.014 1.711 4.241 4.938 3.229 3.227 4.941 4.244 4.941 4.244l0.33 0.194 0.333-0.189c0 0 2.372-1.358 2.932-1.652 0.488-0.256 0.927-0.222 0.978-0.191 0.457 0.281 5.534 3.655 5.847 3.875 0.010 0.008 0.287 0.426-0.116 0.999h-0.003c-0.243 0.349-1.038 1.358-1.866 2.22-0.416 0.431-0.841 0.834-1.203 1.105s-0.684 0.356-0.632 0.359c-0.563-0.018-1.804-0.031-4.084-1.102-2.279-1.069-5.532-3.209-9.819-7.499-4.285-4.285-6.425-7.537-7.496-9.819-1.069-2.279-1.082-3.521-1.1-4.081 0.003 0.052 0.088-0.274 0.359-0.635s0.671-0.79 1.102-1.205c0.865-0.829 1.871-1.624 2.22-1.866v0.003c0.287-0.201 0.537-0.227 0.718-0.207z"
					></path>
				</symbol>
				<symbol id="icon-clock" viewBox="0 0 32 32">
					<path
						d="M17.52 15.338v0.033l0.023 0.023 5.875 5.875-2.149 2.149-6.789-6.789v-10.149h3.040v8.857zM0.080 16c0-8.773 7.147-15.92 15.92-15.92s15.92 7.147 15.92 15.92c0 8.773-7.147 15.92-15.92 15.92s-15.92-7.147-15.92-15.92zM28.88 16c0-7.132-5.748-12.88-12.88-12.88s-12.88 5.748-12.88 12.88c0 7.132 5.748 12.88 12.88 12.88s12.88-5.748 12.88-12.88z"
					></path>
				</symbol>
				<symbol id="icon-location" viewBox="0 0 22 32">
					<path
						d="M10.774 0c-5.935 0-10.774 4.84-10.774 10.774 0 1.708 0.67 3.599 1.586 5.685s2.115 4.312 3.309 6.371c2.388 4.117 4.772 7.565 4.772 7.565l1.107 1.605 1.107-1.605c0 0 2.383-3.448 4.772-7.565 1.194-2.059 2.393-4.286 3.309-6.371s1.586-3.976 1.586-5.685c0-5.935-4.84-10.774-10.774-10.774zM10.774 2.694c4.479 0 8.081 3.602 8.081 8.081 0 0.895-0.508 2.662-1.36 4.601s-2.009 4.098-3.172 6.103c-1.772 3.055-2.804 4.523-3.549 5.629-0.745-1.106-1.776-2.574-3.549-5.629-1.163-2.005-2.321-4.164-3.172-6.103s-1.36-3.706-1.36-4.601c0-4.479 3.602-8.081 8.081-8.081zM10.774 8.081c-0.714 0-1.4 0.284-1.905 0.789s-0.789 1.19-0.789 1.905c0 0.714 0.284 1.4 0.789 1.905s1.19 0.789 1.905 0.789c0.714 0 1.4-0.284 1.905-0.789s0.789-1.19 0.789-1.905c0-0.714-0.284-1.4-0.789-1.905s-1.19-0.789-1.905-0.789z"
					></path>
				</symbol>
				<symbol id="icon-close" viewBox="0 0 32 32">
					<path
						stroke-linejoin="round"
						stroke-linecap="round"
						stroke-miterlimit="4"
						stroke-width="1.8"
						d="M5.282 1.809c-0.965-0.953-2.519-0.944-3.473 0.021s-0.944 2.519 0.021 3.473l10.934 10.804-10.384 10.621c-0.948 0.97-0.931 2.524 0.039 3.472s2.524 0.931 3.472-0.039l10.366-10.602 10.298 10.176c0.965 0.953 2.519 0.944 3.473-0.021s0.944-2.519-0.021-3.473l-10.316-10.194 10.51-10.75c0.948-0.97 0.931-2.524-0.039-3.472s-2.524-0.931-3.472 0.039l-10.492 10.731-10.915-10.786z"
					></path>
				</symbol>
			</defs>
		</svg>
	</div>
</template>
<script>
export default {
	name: 'SvgSprite'
};
</script>
