<template>
    <b-modal centered static v-model="openModal" class="login-modal">
        <!-- <b-form @submit="loginUser"> -->
            <template #modal-header>
                <b-button @click="close()" class="close">
                    <svg class="icon icon-close">
                        <use xlink:href="#icon-close"></use>
                    </svg>
                </b-button>

                <div class="modal-top p-3 pb-4">
                    <h4 class="modal-title">
                        Login
                    </h4>
                </div>
            </template>
            <template #default>
                <b-card>
                    <b-card-text class="d-flex flex-column  ">
                            <b-form-input type="email" v-model="email" :state="validateEmail" placeholder='Email' required></b-form-input>
                            <b-form-invalid-feedback :state="validateEmail">{{invalidFeedback}}</b-form-invalid-feedback>
                    </b-card-text>
                </b-card>
            </template>
            <template #modal-footer>
                <b-button variant="primary" class="float-right mr-3 mb-3 mt-3" @click="loginUser()">
                    Login
                    <div v-if="loginLoader" class="loader">
                        <b-spinner label="Spinning"></b-spinner>
                    </div>
                </b-button>
            </template>
    </b-modal>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
export default{
    data : ()=>({
        loginModal:true,
        email:'',
        emailRegex:/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        validateEmail:null,
        invalidFeedback:''
    }),
    computed:{
    ...mapGetters({
        isValidUser:'auth/isValidUser',
        openLoginModal:'auth/openLoginModal',
        loginLoader:'auth/loginLoader'
    }),
    openModal(){
        return this.loginModal
    }
    },
    watch:{
        openLoginModal:{
            handler(val){
                const modalAlreadyClosed = JSON.parse(localStorage.getItem('loginClosed'))
                if(modalAlreadyClosed)
                    this.loginModal = false
                else if(!modalAlreadyClosed && !val )
                    this.loginModal = false
                else if (!modalAlreadyClosed && val)
                    this.loginModal = true
            },immediate:true
        }
    },
    methods:{
        ...mapActions({
            authenticateUser:'auth/authenticateUser'
        }),
        async loginUser(){
            
            if(!this.emailRegex.test(this.email)){
                this.validateEmail = false
                this.invalidFeedback = 'Invalid Email'
                return 
            }
            else{
                await this.authenticateUser({username:this.email})
                if(this.openLoginModal){
                    this.validateEmail = false
                    this.invalidFeedback = 'Invalid User'
                }
                else{
                    this.close()
                }
                
            }
            
        },
        close(){
            this.validateEmail = null
            this.email = ''
            this.$store.commit('auth/SET_OPEN_LOGIN_MODAL',false)
            localStorage.setItem('loginClosed',true)
        }
    }
}
</script>
<style lang="scss">
.login-modal {
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    .modal-top {
                        .modal-title {
                            line-height: 1.6rem;
                        }
                    }
                }
                .modal-body {
                    margin-bottom: 2rem;
                    .card {
                        border: none;
                        outline: none;
                        cursor: default;
                        min-height: auto;
                        .card-body {
                            border: none;
                            outline: none;
                            padding: 0 0.875rem;
                        }
                    }
                }
                .modal-footer {
                    .btn {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .loader {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background-color: #2195f39f;
                            cursor: default;
                            .spinner-border {
                                height: 1.3rem;
                                width: 1.3rem;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}
</style>