import axios from 'axios';
import {store} from "@/store/store.js"

export default function getStoreInfo(storeAlias) {
	const orderApiUrl = 'https://api.order.biyo.co';
	return fetch(`${orderApiUrl}/stores/${storeAlias}/`);
}

export async function createOrder(order) {
	// const orderApiUrl = 'http://api.order.biyo.co/orderlive/addorder';

	// fetch dynamic base url from store
	const orderApiUrl = `${store.getters.baseUrl}/api/orderlive/addorder`;
	return new Promise((resolve,reject)=>{
		axios.post(`${orderApiUrl}`, order).then(res=>{console.log(res.status);resolve(res)}).catch(err=>{
			reject(err.response)
		})
	})
}

export async function login(loginCredentials){
	const loginUrl = `${store.getters.baseUrl}/api/orderlive/login/`;
	// const loginUrl = 'http://localhost:8000/api/orderlive/login/'
	return new Promise((resolve,reject)=>{
		axios.post(loginUrl,loginCredentials).then(res=>resolve(res)).catch(err=>reject(err))
	})
}

export async function fetchAddresses(customerId){
	const getAddressesUrl = `${store.getters.baseUrl}/api/orderlive/addresses/customer?id=${customerId}`
	// const getAddressesUrl = `http://localhost:8000/api/orderlive/addresses/customer?id=${customerId}`
	return new Promise((resolve, reject)=>{
		axios.get(getAddressesUrl).then(res=>resolve(res)).catch(err=>reject(err))
	})
}

export const fetchCountries = ()=>{
	const apiUrl = `${store.getters.baseUrl}/api/countries`
	return new Promise((resolve, reject)=>{
		axios.get(apiUrl)
		.then(res=>{
			if(res.status === 200){
				resolve(res.data)
			}
		})
		.catch(err=>{
			reject(err)
		})
	})
}
export const fetchUSStates = ()=>{
	const apiUrl = `${store.getters.baseUrl}/api/states`
	return new Promise((resolve, reject)=>{
		axios.get(apiUrl)
		.then(res=>{
			if(res.status === 200){
				resolve(res.data)
			}
		})
		.catch(err=>{
			reject(err)
		})
	})
}
// export function getCategories(storeAlias) {
//   state.getters.getConfig
//   const orderApiUrl = 'http://api.order.biyo.co';
//   return fetch(`${orderApiUrl}/stores/${storeAlias}/`);
// }
