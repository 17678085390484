<template>
	<div>Store is not found, check url and try again</div>
</template>

<script>
export default {
	name: 'NotFound',
	components: {}
};
</script>
