// import axios from 'axios'
import { fetchAddresses, fetchCountries, fetchUSStates } from "../../helpers/apiHelper";

export const address= {
         namespaced: true,
         state: {
            addresses:[],
            countries:[],
            states:[]
         },
         getters: {
            addresses : (state) => state.addresses,
            addressOptions : state => state.addresses.map(address=>{return {value:address.full_address,text:address.full_address}}),
            countries : state => state.countries,
            states: state => state.states
         },
         mutations: {
            SET_ADDRESSES:(state,addresses) => state.addresses = addresses,
            SET_COUNTRIES: (state, countries) => { state.countries = countries },
            SET_US_STATES: (state, states) => { state.states = states }
         },
         actions: {
            fetchCustomerAddresses({commit},id){
                fetchAddresses(id).then((res)=>commit('SET_ADDRESSES',res.data)).catch((err)=>{throw new Error(err)})
            },
            async fetchCountriesAndStates({commit}){
               let retrivedCountries  = await fetchCountries()
               let retrivedUSAStates  = await fetchUSStates()

               retrivedCountries = Object.entries(retrivedCountries).map(([key,value])=>{
                  return {'value':key, 'text':value}
                })
            
                retrivedUSAStates = retrivedUSAStates.map(state=>{
                  return {value:state,text:state}
                })

               commit('SET_COUNTRIES', retrivedCountries)
               commit('SET_US_STATES', retrivedUSAStates)
            },
            async fetchUSStates({commit}){
               const response  = await fetchUSStates()
               commit('SET_US_STATES', response)
            }
         }
       };
