<template>
    <b-modal id="restaurant-closing-modal" width="20px" centered static class="restaurant-close-modal" hide-header hide-footer >
            <template #modal-header>
            </template>
            <template #default>
                <b-card>
                    <div class="d-flex flex-column justify-content-center align-items-center py-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                        </svg>
                        <h4 class="mt-4 mb-0">The restaurant is closed</h4>
                    </div>
                </b-card>
            </template>
            <template #modal-footer>
            </template>
    </b-modal>
</template>
<script>
export default{

}
</script>

<style lang="scss">
.restaurant-close-modal {
    .modal {
        .modal-dialog {
            min-width: 390px;
            max-width: 430px;
            .modal-content {
                .modal-body {
                    padding: 30px 0;
                    .card {
                        border: none;
                        outline: none;
                        cursor: default;
                        min-height: auto;
                        .card-body {
                            border: none;
                            outline: none;
                            padding: 0 0.875rem;
                        }
                    }
                }
            }
        }
    }
}
</style>