import Vue from 'vue';
import Router from 'vue-router';
import NotFound from './views/NotFound';
import OrderView from './views/OrderView';
import Checkout from './views/Checkout';

Vue.use(Router);

export const router = new Router({
	mode: 'history',
	routes: [
		{ path: '/', name: 'NotFoundHome', component: NotFound },
		{ path: '/notfound', name: 'NotFound', component: NotFound },
		{ path: '/:storeAlias', name: 'OrderView', component: OrderView },
		{ path: '/:storeAlias/checkout', name: 'Checkout', component: Checkout }
		// {path: '/form', name: 'FormView', component: FormView}
	]
});
