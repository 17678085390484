import axios from 'axios'

export const order = {
         namespaced: true,
         state: {
           searchedPlaces: []
         },
         getters: {
           searchedPlaces: (state) => state.searchedPlaces
         },
         mutations: {
           searchedPlaces: (state, searchedPlaces) =>
             (state.searchedPlaces = searchedPlaces)
         },
         actions: {
             async searchPlace({ commit }, place) {
                 if (place !== '') {
                     const url = `https://nominatim.openstreetmap.org/search?q=${place}&format=json`;
                     const searchResults = (await axios.get(url)).data;
                     commit("searchedPlaces", searchResults);
                 } else { 
                     commit("searchedPlaces", []);
                 }
           }
         }
       };
