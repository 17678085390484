import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import vueSmoothScroll from 'vue2-smooth-scroll';
import App from './App.vue';
import { router } from './router';
import { store } from './store/store';
import { Vuelidate } from "vuelidate";
import VueScrollactive from 'vue-scrollactive';
import indexDbHelper from '@/helpers/indexDbHelper';

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(vueSmoothScroll);
Vue.use(VueScrollactive);
Vue.use(Vuelidate)

indexDbHelper.initialize()

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
